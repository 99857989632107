import { Stack, Box } from '@mui/material';

import Image from '../../components/image';
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from './styles';

type Props = {
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration }: Props) {
  return (
    <StyledRoot>
      <Box
        component="img"
        src="/logo/skeelo.svg"
        sx={{
          cursor: 'pointer',
          height: 40,
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
          position: 'absolute',
          width: 40,
          zIndex: 9,
        }}
      />

      <StyledSection>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={
            illustration || '/assets/illustrations/illustration_dashboard.png'
          }
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
