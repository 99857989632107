import { useRouter } from 'next/router';
import { useEffect } from 'react';

import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { push } = useRouter();

  const { isAuthenticated, isInitialized, user } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.is_skeelo_business_user) {
        push('/choose-platform');
      } else {
        push('/');
      }
    }
  }, [isAuthenticated]);

  if (isInitialized === isAuthenticated) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
