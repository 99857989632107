import LoginLayout from '@/layouts/login/LoginLayout';
import { Stack, Typography } from '@mui/material';

import AuthLoginForm from './AuthLoginForm';

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Skeelo</Typography>

        <Typography variant="body2">
          Insira abaixo os seus dados de acesso.
        </Typography>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
